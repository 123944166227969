import React from 'react';
import ColorCloudsLoader from './components/colorCloudsLoader';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="scene-container">
        <ColorCloudsLoader />
      </div>
    </div>
  );
}
export default App;