import React, { useState, useEffect, useRef } from 'react';
import { ColorClouds } from './colorClouds';
import { motion, AnimatePresence } from 'framer-motion';

const ColorCloudsLoader = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const audioRef = useRef(null);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setIsLoaded(true);
    }
  };

  const handleEnterClick = () => {
    setIsLoaded(true);
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust this value as needed
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);

    // Start playing music by default
    if (audioRef.current) {
      audioRef.current.play().catch(error => {
        console.log("Autoplay prevented. User interaction may be required to play audio.");
      });
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  if (isLoaded) {
    return <ColorClouds />;
  }

  const colorfulTitle = [
    { letter: 'C', color: '#FF0000' }, // Red
    { letter: 'o', color: '#FF7F00' }, // Orange
    { letter: 'l', color: '#FFFF00' }, // Yellow
    { letter: 'o', color: '#00FF00' }, // Green
    { letter: 'r', color: '#0000FF' }, // Blue
    { letter: ' ', color: '#FFFFFF' }, // White space
    { letter: 'C', color: '#4B0082' }, // Indigo
    { letter: 'l', color: '#9400D3' }, // Violet
    { letter: 'o', color: '#FF1493' }, // Deep Pink
    { letter: 'u', color: '#00FFFF' }, // Cyan
    { letter: 'd', color: '#FF69B4' }, // Hot Pink
    { letter: 's', color: '#1E90FF' },  // Dodger Blue
    { letter: '!', color: '#FFD700' }  // Gold
  ];

  const CloudEffect = () => (
    <div className="absolute inset-0 z-0 overflow-hidden">
      {[...Array(10)].map((_, i) => (
        <motion.div
          key={i}
          className="absolute bg-white rounded-full opacity-70"
          style={{
            width: Math.random() * 200 + 50,
            height: Math.random() * 200 + 50,
            top: `${Math.random() * 100}%`,
            left: `${Math.random() * 100}%`,
          }}
          animate={{
            x: [0, Math.random() * 100 - 50],
            y: [0, Math.random() * 50 - 25],
          }}
          transition={{
            repeat: Infinity,
            repeatType: "reverse",
            duration: Math.random() * 20 + 10,
          }}
        />
      ))}
    </div>
  );

  return (
    <div className="relative h-screen w-screen">
      <audio ref={audioRef} loop>
        <source src={`${process.env.PUBLIC_URL}/audio/background_music.mp3`} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      <video
        autoPlay
        loop
        muted
        playsInline
        className={`absolute z-0 w-full h-full object-cover ${videoLoaded ? 'opacity-100' : 'opacity-0'}`}
        style={{ objectPosition: isMobile ? 'center center' : 'initial' }}
        onCanPlay={() => setVideoLoaded(true)}
      >
        <source src={`${process.env.PUBLIC_URL}/videos/background.m4v`} type="video/mp4" />
        <source src={`${process.env.PUBLIC_URL}/videos/background.webm`} type="video/webm" />
        Your browser does not support the video tag.
      </video>
      {!videoLoaded && (
        <div className="absolute z-0 w-full h-full bg-gradient-to-br from-purple-400 to-pink-500"></div>
      )}
      <CloudEffect />
      <motion.div 
        className="relative z-10 flex flex-col items-center justify-center h-full w-full"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <motion.h1 
          className="text-5xl md:text-7xl font-bold mb-8 font-comic-sans text-center px-4"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.5, type: "spring", stiffness: 120 }}
        >
          <span className="text-white">Welcome to </span>
          {colorfulTitle.map((item, index) => (
            <span key={index} style={{ color: item.color }}>{item.letter}</span>
          ))}
        </motion.h1>
        <motion.div 
          className="bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg rounded-3xl p-8 max-w-lg border-4 border-pink-200 shadow-xl mx-4"
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ delay: 0.8, type: "spring", stiffness: 100 }}
        >
          <h2 className="text-4xl font-semibold text-purple-700 mb-6">Ready to Play?</h2>
          <p className="text-2xl text-indigo-800 mb-8">
           🪄 Click on magic rings to paint the clouds!
          </p>
          <motion.button 
            onClick={handleEnterClick}
            className="bg-gradient-to-r from-pink-400 to-purple-500 text-white text-3xl px-10 py-4 rounded-full hover:from-pink-500 hover:to-purple-600 transition-colors duration-300 shadow-lg"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Let's Go! 🎨
          </motion.button>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default ColorCloudsLoader;
